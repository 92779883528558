#container {
    width: 100%;
    height: 100%;
}

#__qiankun_microapp_wrapper_for_startups_web_prod__ {
    width: 100%;
    height: 100%;
}

#__qiankun_microapp_wrapper_for_startups_edu_web_prod__ {
    width: 100%;
    height: 100%;
}

#__qiankun_microapp_wrapper_for_startups_data_web_prod__ {
    width: 100%;
    height: 100%;
}
